@import 'src/styles/variables';

.button {
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.active {
  & svg {
    path {
      fill: $engine-pink;
    }
  }
}

.buttonRotate {
  transform: rotate(90deg);
}
