@import 'src/styles/variables';

.container {
  width: 100%;
  margin-top: 16px;
  border: 1px solid $secondary-text;
  padding: 11px 16px 16px;
  border-radius: $border-radius;

  & .inputContainer {
    margin-bottom: 8px;

    & > input {
      border: none;
      padding-inline: 0;
      color: $secondary-text;
      height: 24px;
      line-height: 16px;
    }
  }

  & .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;

    .button {
      padding-inline: 8.5px;
      height: 24px;
      width: 74px;
    }
  }
}
