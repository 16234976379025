@import 'src/styles/variables';

.container {
  padding: 24px;

  & h2 {
    color: $dark;
    font-size: $font-l;
    font-weight: 700;
  }

  .headerContainer {
    display: flex;
    column-gap: 24px;
    margin-top: 24px;

    & .inputHeader {
      width: 356px;
    }
  }

  .loaderContainer {
    width: 100%;
    margin: 15px 0 17px;
    text-align: center;
  }

  & .button1 {
    width: 108px;
    padding-inline: 0;
  }

  & .button2 {
    width: 117px;
    padding-inline: 0;
  }

  .scrapperButton {
    margin: 8px 0;
    max-width: 250px;
  }

  .footerContainer {
    column-gap: 24px;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
}
