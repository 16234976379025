@import 'src/styles/variables';

.body {
  padding: 36px 24px 0 32px;

  & .tableWrapper {
    display: flex;
  }
}
.footer {
  height: 51.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .copyrightText {
    color: $secondary-text;
    font-size: $font-sm;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
