@import 'src/styles/variables';

.filtersWrapper {
  background-color: $wrapper-bg;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 24px;
  min-height: 80vh;
  min-width: 312px;
  display: flex;
  flex-direction: column;

  & .text {
    font-weight: bold;
    font-size: $font-xxl;
    color: $primary-text;
  }

  & .filtersContainer {
    display: flex;
    flex-direction: column;
    row-gap: 63px;
    margin-top: 36px;
  }

  & .filterButton {
    margin-top: 8px;
  }

  & .newTherapy {
    background-color: $gray11;
    padding: 16px 16px 11px;
    border-radius: 8px;

    & p {
      word-wrap: break-word;
      width: 232px;
      font-size: $font-reg;
      color: $dark;
      line-height: 19.2px;
    }

    & .newTherapyButton {
      margin-top: 24px;
      width: 100%;
    }
  }

  .bottomActions {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
}
