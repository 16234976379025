@import 'antd/dist/antd.css';
@import '../../node_modules/reset-css/sass/reset';
@import 'variables';
@import './global/pagination';
@import './global/modal';
@import 'mixins';

body {
  margin: 0;
  font-family: 'SimonKucher', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
  height: 100vh;
  background-color: $main-bg;
}

.ant-select-selection-placeholder {
  color: $gray55;
}

.ant-modal {
  //-webkit-transition: width 1s linear .3s, height .3s linear .5s !important;
  //transition: width 2s, min-height 4s !important;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

//.rc-virtual-list-scrollbar {
//  display: block !important;
//}
