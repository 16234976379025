@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  margin-top: 24px;

  & h3 {
    font-size: $font-reg;
    font-weight: 700;
  }

  & .list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-height: 300px;
    overflow-y: auto;

    & .select {
      width: 200px;
    }

    & .input {
      width: 120px;
    }

    & .inputPackageUnits {
      width: 200px;
    }

    & .inputOther {
      flex-grow: 1;
    }

    & .item {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 24px;
      column-gap: 24px;
      border: 1px solid $gray25;
      border-radius: 8px;
      padding: 16px;

      & .row {
        display: flex;
        align-items: center;
        column-gap: 16px;
        flex-wrap: wrap;
        row-gap: 24px;
        width: 100%;
      }

      & .deleteButtonContainer {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 120px;

        @include forTablet {
          position: static;
          margin-left: -7px;
        }

        &:hover svg {
          path {
            fill: $engine-pink-pos-20;
          }
        }

        &:active svg {
          path {
            fill: $engine-pink-neg-40;
          }
        }

        & button {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
        }

        & svg {
          path {
            fill: $engine-pink;
          }
        }
      }
    }
  }
}
