@import 'src/styles/variables';

.container {
  border-radius: $border-radius;
  border: 1px solid $gray55;
  overflow: hidden;
  box-shadow: $box-shadow;

  & li {
    padding: 0;
    height: 34px;
  }

  & span {
    display: block;
    width: 100%;
    height: 34px;
  }

  & button {
    background-color: transparent;
    padding-inline: 16px;
    text-align: left;
    width: 100%;
    display: block;
    height: 100%;
    outline: none;
    border: none;
    font-size: $font-reg;
    color: $primary-text;
    cursor: pointer;
  }
}
