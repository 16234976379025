@import 'src/styles/variables';

.container {
  margin-top: 24px;

  .tabs {
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
  }

  & .round {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $secondary-text;
    cursor: pointer;
    background-color: transparent;
  }

  .phase {
    width: 70px;
    height: 24px;
    border-radius: 16px;
    border: 1px solid $secondary-text;
    font-weight: 700;
    color: $primary-text;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
  }

  .selected {
    background-color: $engine-pink;
    border: none;
    color: $white;
  }

  .errorPhaseTab {
    border-color: $engine-pink;
  }

  .confirmContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 12px;
    border: 1px solid $engine-pink;
    color: $engine-pink;
    font-size: 12px;
    padding: 0 8px 0 32px;
  }

  .confirmButton {
    width: 50px;
    margin-left: 10px;
    font-size: 12px;
  }

  & .roundConfirmCancel {
    position: absolute;
    left: -2px;
    background-color: $white;
    border-color: $engine-pink-neg-40;
    margin-left: -1px;
    margin-right: 8px;
    z-index: 11;
  }

  .phases {
    display: flex;
    align-items: center;
    row-gap: 59px;
    flex-wrap: wrap;
    column-gap: 18px;
    margin-top: 42px;

    & .phaseInput {
      width: 220px;
    }
    & .phaseInput2 {
      width: 114px;
    }

    & .phaseSelect {
      width: 112px;
    }

    & .phaseSelect2 {
      width: 220px;
    }
  }

  .inlineText {
    font-size: 16px;
  }
}
