@import 'src/styles/variables';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 42px;
}
