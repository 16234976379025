@import 'src/styles/variables';

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 39px;

  & svg:hover {
    cursor: pointer;
    path {
      fill: $engine-pink-neg-40;
    }
  }
}

.container {
  padding: 24px;

  & h3 {
    color: $dark;
    font-size: $font-l;
    font-weight: 700;
  }

  & .buttonsContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    column-gap: 24px;
  }
}