@import 'src/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 71px;
  border-bottom: 1px solid $gray25;
  padding: 0 24px 0 28px;
  & .text {
    font-size: $font-l;
    font-weight: bold;
    color: $secondary-text;
  }

  & .rightContainer {
    display: flex;
    align-items: center;
  }
}
