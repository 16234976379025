@import 'src/styles/variables';

.selectFieldset {
  position: relative;
  border: $border $gray100;
  border-radius: $border-radius;
  display: flex;

  .selectLegend {
    position: absolute;
    z-index: 10;
    top: -9px;
    background-color: white;
    color: $secondary-text;
    font-size: $font-sm;
    width: auto;
    margin-left: 1rem * 0.5;
    margin-bottom: 0;
    padding-inline: 4px;
  }
  .transparent {
    visibility: hidden;
  }

  .appSelect {
    min-width: 100%;
    height: 40px;
    //padding-block: 10.5px;

    & > .ant-select-selector {
      margin-top: 0;
    }

    & > span {
      top: 43%;
    }

    & input {
      font-size: $font-reg;
      color: $primary-text;
    }

    & .highlight mark {
      font-weight: 700;
      background-color: transparent;
    }

    & .arrowUp {
      transform: rotate(-180deg);
    }

    & .arrow {
      margin-top: 5px;
    }

    & .cleanIcon {
      background-color: white;
      width: 16px;
    }
  }
}

.editIconContainer {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    max-width: 160px;
    overflow: hidden;
  }

  & .dots {
    margin-left: -5px;
  }
}

.error {
  border-color: $engine-pink;
}
