@import 'src/styles/variables';

.fieldset {
  width: 120px;
  height: 40px;
  border: 1px solid $secondary-text;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  & input {
    text-align: center;
    color: $gray100;
    font-size: $font-reg;
  }

  .legend {
    top: -11px;
    position: absolute;
    background-color: white;
    color: $secondary-text;
    font-size: $font-sm;
    width: auto;
    margin-bottom: 0;
    margin-left: 14px;
    padding-inline: 4px;
  }
}
