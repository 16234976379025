@import 'src/styles/variables';

.button {
  border: none;
  border-radius: $border-radius;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

// sizes

.small {
  height: 32px;
  padding-inline: 12px;
  font-size: $font-sm;
}
.large {
  height: 40px;
  padding-inline: 28px;
  font-size: $font-reg;
}

.primary {
  background-color: $engine-pink;
  color: $white;
  font-weight: bold;
  &:hover {
    background-color: $engine-pink-pos-20;
  }
  //&:focus {
  //  background-color: $engine-pink-neg-20;
  //}
  &:disabled {
    cursor: not-allowed;
    background-color: $engine-pink-neg-70;
  }
}

.secondary {
  background-color: transparent;
  border: $border $engine-pink;
  color: $engine-pink;
  &:hover {
    background-color: #da1b5e0d;
    color: $engine-pink-pos-20;
    border-color: $engine-pink-pos-20;
  }
  &:active {
    border-color: $engine-pink-neg-40;
    color: $engine-pink-neg-40;
    background-color: transparent;
  }
  &:disabled {
    cursor: not-allowed;
    color: $engine-pink-neg-70;
    border-color: $engine-pink-neg-70;
    background-color: transparent;
    pointer-events: auto;
  }
}

.transparent {
  background-color: transparent;
  font-weight: 700;
  color: $engine-pink;

  &:hover {
    color: $engine-pink-pos-40;
  }
  &:active {
    color: $engine-pink-neg-20;
  }
  &:disabled {
    cursor: not-allowed;
    color: $engine-pink-neg-70;
  }
}

.disabled {
  color: $engine-pink-neg-70;
  border-color: $engine-pink-neg-70;
  background-color: transparent;
  cursor: not-allowed;
}

.loading {
  background-color: $engine-pink-neg-40;
}