@import 'src/styles/variables';

.button {
  font-size: $font-reg;
  color: $engine-pink;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-weight: 700;
  width: 100%;

  &:hover {
    color: $engine-pink-pos-20;
  }

  &:disabled {
    color: $engine-pink-neg-70;
    cursor: not-allowed;
  }

  &:active {
    color: $engine-pink-neg-40;
  }
}

.disabled {
  color: $engine-pink-neg-70;
  cursor: not-allowed;
}
