@import 'src/styles/variables';

.table {
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 16px;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-left: 32px;
  width: 100%;
  height: calc(100vh - 170px);
  color: $primary-text;
  padding: 0 15px 15px 15px;
  position: relative;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  & div div {
    color: $engine-pink;
    font-size:$font-reg;
  }
}

.tableHeaderContainer {
  position: sticky;
  top: 0;
}

.tableHead {
  height: 67px !important;
  background-color: $gray25;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  font-size: $font-reg;
  font-weight: bold;
  color: $primary-text;

  & div {
    display: flex;
    align-items: center;
    padding-inline: 16px;
    &:first-child {
      padding-inline: 24px;
    }

    & svg {
      margin-left: 8.5px;
    }
  }

  & div:not(:last-child) {
    border-right: 1px solid #fff;
  }
}

.cols {
  display: grid;
  grid-template-columns: repeat(2, 160px) minmax(230px, auto) repeat(2, 195px) 90px 197px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &:not(:first-child) {
    background-color: $wrapper-bg;
  }

  .lastDownload {
    & h4 {
      font-weight: 700;
    }
  }

  & .col {
    padding: 16px 16px 16px;

    &:first-child {
      padding: 16px 24px 16px;
    }

    &:not(:last-child) {
      border-right: 1px solid $gray25;
    }

    & .indication {
      & p {
        line-height: 19px;
      }

      & h4:first-of-type,
      & h4:last-of-type {
        font-weight: 700;
      }

       & .phasesContainer {
         display: flex;
         flex-direction: column;
         row-gap: 3px;
       }

      & .dosage {
        margin-top: 20px;
      }
    }

    & .download {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:first-child {
        margin-bottom: 20px;
      }
    }

    & .comment {
      margin-top: 16px;
    }

    & .commentText {
      word-break: break-word;
    }

    & svg:hover {
      cursor: pointer;
      path {
        fill: $engine-pink-neg-40;
      }
    }

    .dosageTitle {
      font-weight: 700;
    }
  }
}

.tableRow {
  //& .ant-table-row {
  //  margin-top: 20px;
  //}
  background-color: $main-bg;
}
