@import 'src/styles/variables';

.container {
  padding: 24px;

  & h2 {
    color: $dark;
    font-size: $font-xxl;
    font-weight: 700;
  }

  .headerContainer {
    display: flex;
    column-gap: 24px;
    margin-top: 24px;

    & .inputHeader {
      width: 356px;
    }
  }

  .footerContainer {
    column-gap: 24px;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    & .button1 {
      width: 108px;
      padding-inline: 0;
    }

    & .button2 {
      width: 117px;
      padding-inline: 0;
    }
  }
}
