@import 'src/styles/variables';

.container {
  padding: 24px;

  & h2 {
    font-size: $font-l;
    font-weight: 700;
    color: $primary-text;
  }

  & .button1 {
    width: 108px;
    padding-inline: 0;
  }

  & .button2 {
    width: 200px;
    padding-inline: 0;
  }

  .popover {
    height: 36px;
    border-radius: 8px;
    font-size: $font-sm;
  }

  .headerContainer {
    display: flex;
    column-gap: 24px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 24px;
    row-gap: 24px;

    & .inputHeader {
      width: 356px;
    }

    & .country {
      width: 200px;
    }

    & .indication {
      width: 356px;
    }
  }

  .footerContainer {
    column-gap: 24px;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }

  .scrapperButton {
    margin: 16px 0;
    width: 250px;
  }

  .loaderContainer {
    width: 100%;
    margin: 16px 0 20px;
    text-align: center;
  }

  .buttonContainer {
    display: flex;
    column-gap: 24px;
    justify-self: flex-end;
  }
}
