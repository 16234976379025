@import '../variables';

.ant-pagination-item-active {
  background-color: transparent;
  color: $primary-text;
  font-weight: bold;

  & a {
    color: $primary-text;
    font-weight: bold;
  }
}

.ant-pagination-item {
  border: none;

  &:hover {
    color: $primary-text;
  }
  &:hover a {
    color: $primary-text;
  }
  &:focus-visible a {
    color: $primary-text;
  }
}

.ant-pagination-item a:hover {
  color: $primary-text;
  font-weight: bold;
}

.ant-pagination-prev:hover,
.ant-pagination-next:hover {
  & svg {
    fill: $engine-pink;
  }
}

.ant-pagination-prev:focus-visible,
.ant-pagination-next:focus-visible {
  & svg {
    fill: $engine-pink;
  }
}

.ant-pagination-options-quick-jumper {
  & input:hover {
    border-color: $engine-pink;
  }
  & input:focus {
    border-color: $engine-pink;
    box-shadow: 0 0 0 2px $engine-pink-pos-20-op20;
  }
}

.ant-table-column-sorter {
  display: none;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $engine-pink;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $engine-pink;
}
