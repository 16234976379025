@import 'src/styles/variables';

.container {
  padding: 40px 24px 36px;
  border-radius: 8px;
  max-height: 402px;

  & h2 {
    font-size: $font-xxl;
    font-weight: 700;
    line-height: 48px;
    color: $primary-text;
  }

  .text {
    margin: 17px 0 40px -8px;
    font-size: $font-reg;
    color: $primary-text;
    line-height: 19.2px;
  }

  .icon {
    margin: 0 auto;
    width: 100%;
  }
}
