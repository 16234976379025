@import 'src/styles/variables';

.container {
  padding: 24px;

  & .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & h2 {
      font-weight: 700;
      font-size: $font-l;
      color: $primary-text;
      line-height: 28px;
    }
  }

  & .listContainer {
    margin-top: 24px;

    h3 {
      font-size: $font-reg;
      color: $primary-text;
      font-weight: 700;
      margin-bottom: 16px;
    }

    & .list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      & .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & span {
          color: $primary-text;
          font-size: $font-reg;
        }
      }
    }
  }

  & .bottomText {
    color: $primary-text;
    font-size: $font-reg;
    line-height: 19.2px;
    margin-top: 24px;
  }

  & .button {
    width: 100%;
    margin-top: 24px;
  }
}
