@import 'src/styles/variables';

.container {
  margin-top: 24px;

  & h3 {
    font-size: $font-l;
    font-weight: 700;
    line-height: 28px;
  }

  .selects {
    display: flex;
    column-gap: 24px;
    margin-top: 8px;
    align-items: center;
  }

  .selectFirst {
    width: 200px;
  }

  .selectNext {
    width: 356px;
  }
}
