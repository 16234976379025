//COLORS
$white: #fff;
$primary-text: #191919;
$secondary-text: #5c5c6e;
$dark-secondary-text: #b3b3bb;
$dark-primary-text: #fff;
$main-bg: #f9f9fa;
$wrapper-bg: #fff;
$dark: #000000;

$gray100: #404055;
$gray85: #5c5c6e;
$gray70: #797988;
$gray55: #9696a2;
$gray40: #b3b3bb;
$gray25: #cfcfd4;
$gray10: #ececee;
$gray11: #e3e3e6;

$engine-pink: #da1b5e;
$engine-pink-pos-20: #ba1750;
$engine-pink-pos-20-op20: #ba175033;
$engine-pink-pos-40: #831038;
$engine-pink-neg-40: #e9769e;
$engine-pink-neg-20: #e1497e;
$engine-pink-neg-20-op10: #e1497e1a;
$engine-pink-neg-70: #f4bace;

$pink-pos-30: #b33e5b;
$pink-pos-20: #cc4768;
$pink-pos-10: #e65075;
$pink: #ff5982;
$pink-neg-20: #ff7a9b;
$pink-neg-40: #ff9bb4;
$pink-neg-70: #ffcdd9;

$gold-pos-50: #7e690d;
$gold-pos-30: #a18611;
$gold-pos-15: #c3a314;
$gold: #e5bf18;
$gold-neg-30: #edd25d;
$gold-neg-50: #f2df8c;
$gold-neg-70: #f7ecb9;

$navy-pos-50: #003549;
$navy-pos-30: #004b66;
$navy-pos-15: #005b7c;
$navy: #006b92;
$navy-neg-15: #2681a2;
$navy-neg-30: #4c97b2;
$navy-neg-60: #99c4d3;

$aqua-pos-50: #005660;
$aqua-pos-30: #007987;
$aqua-pos-15: #0092a3;
$aqua: #00acc0;
$aqua-neg-20: #33bdcd;
$aqua-neg-50: #80d6e0;
$aqua-neg-70: #b2e6ec;

$green-pos-50: #005443;
$green-pos-30: #00765f;
$green-pos-15: #008f73;
$green: #00a887;
$green-neg-25: #40bea5;
$green-neg-50: #80d4c3;
$green-neg-70: #b2e5db;

$lilac-pos-40: #714e71;
$lilac-pos-20: #966897;
$lilac-pos-10: #aa75aa;
$lilac: #bc82bd;
$lilac-neg-20: #c99bca;
$lilac-neg-40: #d7b4d7;
$lilac-neg-60: #e4cde5;

$purple-pos-50: #2a294f;
$purple-pos-30: #3b3a70;
$purple-pos-15: #474687;
$purple: #54529f;
$purple-neg-20: #7675b2;
$purple-neg-50: #aaa9cf;
$purple-neg-70: #cbcbe2;

$orange-pos-50: #975733;
$orange-pos-20: #ca7444;
$orange-pos-10: #e3834d;
$orange: #fc9155;
$orange-neg-20: #fda777;
$orange-neg-40: #fdbd99;
$orange-neg-60: #fed3bb;

$alert-pos-40: #992c2c;
$alert-pos-20: #cc3a3a;
$alert-pos-10: #e64242;
$alert: #ff4949;
$alert-neg-20: #ff6d6d;
$alert-neg-40: #ff9292;
$alert-neg-60: #ffb6b6;

$caution-pos-50: #94671c;
$caution-pos-20: #c58926;
$caution-pos-10: #de9a2a;
$caution: #f6ab2f;
$caution-neg-20: #f8bc59;
$caution-neg-40: #facd82;
$caution-neg-60: #fbddac;

//FONTSIZE
$font-sm: 12px;
$font-m: 14px;
$font-reg: 16px;
$font-l: 24px;
$font-xl: 32px;
$font-xxl: 40px;
$font-3xl: 48px;

//BORDER-RADIUS
$border-radius: 8px;

//BORDER
$border: 1px solid;

//BOX-SHADOW
$box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
$box-shadow-soft-high: 0px 4px 24px rgba(0, 0, 0, 0.2);
$box-shadow-hard-high: 0px 4px 20px rgba(0, 0, 0, 0.1);
