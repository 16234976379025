@import 'src/styles/variables';

.container {
  padding: 39px 24px 24px;
  min-height: 302px;

  & h2 {
    font-size: $font-xxl;
    color: $primary-text;
    font-weight: 700;
  }

  & p {
    font-size: $font-reg;
    color: $dark;
    margin-top: 16px;
  }

  & .buttons {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
  }
}
