@import 'src/styles/variables';

.fieldset {
  position: relative;

  & .input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 20px 0 16px;
    border: 1px solid $gray100;
    background-color: white;
    outline: none;
    font-size: $font-reg;

    &:focus {
      background-color: white;
    }

    &::placeholder {
      color: $gray55;
    }
  }

  & .topLabel {
    position: absolute;
    font-size: $font-reg;
    top: -35px;
  }

  & .legend {
    top: -11px;
    position: absolute;
    background-color: white;
    color: $secondary-text;
    font-size: $font-sm;
    width: auto;
    margin-bottom: 0;
    margin-left: 14px;
    padding-inline: 4px;
  }

  .error {
    color: $engine-pink;
    position: absolute;
  }

  .errorInput {
    border-color: $engine-pink;
  }

  .readOnly {
    caret-color: transparent;
    cursor: default;
  }

  .rightIcon {
    position: absolute;
    right: 8px;
    top: 7px;
    font-size: $font-reg;
  }
}
