@import 'src/styles/variables';

.container {
  padding: 24px;

  & h3 {
    color: $dark;
    font-size: $font-l;
    font-weight: 700;
  }

  & .buttonsContainer {
    display: flex;
    margin-top: 24px;
    column-gap: 24px;
    justify-content: center;
  }
}