.modal {
  position: relative;
  transition: height 0.7s ease-in-out;

  .closeIcon {
    position: absolute;
    right: 22.75px;
    top: 22.75px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
  }
}
